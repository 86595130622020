import { type Badge, getBadges } from "@/components/common/badge/badge";
import {
  type CommerceModuleProductListItem,
  type DealOfTheDayItem,
  type Instrumentation,
  isDealOfTheDayItem,
  type StreamImage,
  type StreamItem,
} from "@/components/shared/Stream/data";
import { type Attribution, getAttribution } from "./attribution";

type Args = DealOfTheDayItem | StreamItem;

export type StoryItem = {
  attribution: Attribution;
  badges: Badge[];
  bypassModal: boolean;
  cacheId?: string;
  categoryLabel: string;
  commerceItem?: CommerceModuleProductListItem;
  commentCount?: number;
  generatedSummary?: string[];
  id: string;
  instrumentation?: Instrumentation;
  summary: string;
  thumbnail?: StreamImage;
  title: string;
  url: string;
  viewCount?: number;
  wpm200?: number;
};

export const sanitizeStreamData = (items: Args[]): StoryItem[] => {
  return items.map(sanitizeStreamItem);
};

export const sanitizeStreamItem = (item: Args): StoryItem => {
  const { canonicalUrl, clickThroughUrl } = item.content;
  const now = Date.now();

  const isCreatorContent = !!item.content.isCreatorContent;

  const attribution = getAttribution({
    authors: item.content.authors,
    isCreatorContent,
    provider: item.content.provider,
    tags: item.content.tags,
  });

  const badges = getBadges({
    categoryLabel: item.content.categoryLabel,
    isCreatorContent,
    isOpinion: item.content.isOpinion,
    liveBlogStatus: item.content.liveBlogStatus,
    provider: item.content.provider,
    reasons: item.content.reasons,
  });

  if (isDealOfTheDayItem(item)) {
    const { commerceModuleProductList } = item.content;
    const commerceItem =
      commerceModuleProductList?.[0].commerceModuleProduct[0];
    return {
      attribution,
      badges,
      bypassModal: !!item.content.bypassModal,
      cacheId: `${item.content.id}-${now}`,
      categoryLabel: item.content.categoryLabel,
      commentCount: item.content.commentCount || 0,
      commerceItem,
      generatedSummary: item.content?.generatedData?.summary?.texts,
      id: item.content.id,
      instrumentation: item.content.instrumentation,
      summary: item.content.summary,
      thumbnail: item.content.thumbnail ? item.content.thumbnail : undefined,
      title: item.content.title,
      url: clickThroughUrl?.url || canonicalUrl?.url,
      viewCount: item.content.contentBadges?.lifetimeViewCount || 0,
      wpm200: item.content.readingMeta?.wpm200,
    };
  }
  return {
    attribution,
    badges,
    bypassModal:
      isCreatorContent ||
      item.content.presentation === "prestige" ||
      !!item.content.bypassModal,
    cacheId: `${item.id}-${now}`,
    categoryLabel: item.content.categoryLabel,
    commentCount: item.content.commentCount || 0,
    generatedSummary: item.content?.generatedData?.summary?.texts,
    id: item.id,
    instrumentation: item.content.instrumentation,
    summary: item.content.summary,
    thumbnail: item.content.thumbnail ? item.content.thumbnail : undefined,
    title: item.content.title,
    url: clickThroughUrl?.url || canonicalUrl?.url,
    viewCount: item.content.contentBadges?.lifetimeViewCount || 0,
    wpm200: item.content.readingMeta?.wpm200,
  };
};
