import { NCP_ORIGIN } from "@/configs/ncp";
import { SUPERNOVA_S3 } from "@/configs/supernova";
import { FetchError } from "@/lib/http";
import {
  type ImageAsset,
  type LiveBlog,
} from "../../../generated-types/stream-types";
import { fetchWithPerf } from "../fetch";

export interface NCPStream<ItemType> {
  expId: string;
  geminiDedupeToken?: unknown;
  nextPage: boolean;
  pagination: {
    uuids: string;
  };
  remainingUuidCount: number;
  stream: ItemType[];
  totalCount: number;
}

export interface NCPLiveBlog {
  contentType: string;
  description: string;
  id: string;
  lastModifiedTime: string;
  liveBlog: LiveBlog;
  pubDate: string;
  thumbnail: ImageAsset;
  title: string;
  url: string;
}

export interface FetchStreamArgs<ItemType> {
  abortController?: AbortController;
  credentials?: FetchCredential;
  headers?: {
    Cookie?: string;
  };
  isItemType: (item: unknown) => item is ItemType;
  nextPaginationCursor?: string;
  paginationCursor?: string;
  params: NCPStreamViewParams;
  streamKey: string;
}

export interface NCPStreamViewParams {
  authorAlias?: string;
  alias?: string;
  articleStreamCount?: number;
  articleStreamSnippetCount?: number;
  articleStreamThumbnailSizes?: string;
  bucketId?: string;
  categoryLabelFilter?: string;
  configDocId?: string;
  count?: number;
  diagnostics?: boolean;
  feedBlockCount?: number;
  feedBlockSnippetCount?: number;
  feedBlockThumbnailSizes?: string;
  header?: string;
  id?: string;
  imageFormat?: string;
  imageSizes?: string;
  imageTags?: string;
  lang?: string;
  listAlias?: string;
  listId?: string;
  logoImgType?: string;
  namespace?: string;
  uuid?: string;
  snippetCount?: number;
  thumbnailSizes?: string;
  site?: string;
  spaceId?: string;
  ssl?: boolean;
  region?: string;
  version?: string;
  woeid?: string;
}

export interface NCPLiveBlogParams {
  version: string;
  fetchBy: string;
  snippetCount: number;
  uuids: string;
  id: string;
  namespace: string;
}

export interface NCPStreamViewResponse<ItemType> {
  data: Record<string, NCPStream<ItemType>>;
  status: string;
}

export interface NCPDeeplinkViewParams {
  id: string;
  imageFormat?: string;
  imageTags?: string;
  namespace: string;
  uuid: string;
  slidesSnippetCount?: number;
  version: string;
}

export interface NCPDeeplinkViewResponse<ItemType> {
  data: { contents: ItemType[] };
  status: string;
}

const encodeParams = (params: { [key: string]: any }): URLSearchParams => {
  const searchParams = new URLSearchParams();

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      // Handle nested objects (e.g., pageContext)
      if (typeof params[key] === "object" && params[key] !== null) {
        searchParams.set(key, JSON.stringify(params[key]));
      } else {
        // Handle non-nested properties
        searchParams.set(key, params[key]);
      }
    }
  }

  return searchParams;
};

export const FETCH_CREDENTIALS = Object.freeze({
  INCLUDE: "include",
  SAME_ORIGIN: "same-origin",
});

type FetchCredentials = keyof typeof FETCH_CREDENTIALS;
export type FetchCredential = (typeof FETCH_CREDENTIALS)[FetchCredentials];

/**
 * Fetches a deep link from NCP
 *
 * Can be used on the client or server
 */
export const fetchDeeplink = async <ItemType>({
  abortController,
  credentials = FETCH_CREDENTIALS.SAME_ORIGIN,
  params,
  headers = {},
}: {
  abortController?: AbortController;
  credentials?: FetchCredential;
  params: NCPDeeplinkViewParams;
  headers?: {
    Cookie?: string;
  };
}): Promise<ItemType> => {
  let baseUrl = "/api/v1/gql/content_view";
  if (NCP_ORIGIN) {
    baseUrl = new URL(baseUrl, NCP_ORIGIN).toString();
  }

  const searchParams = encodeParams({ ...params });
  const url = `${baseUrl}?${searchParams.toString()}`;

  try {
    const response = await fetch(url, {
      credentials,
      headers,
      method: "GET",
      signal: abortController?.signal,
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    const responseBody: NCPDeeplinkViewResponse<ItemType> =
      await response.json();
    return responseBody.data.contents[0];
  } catch (cause: any) {
    const error: any = new Error("Error fetching deeplink");
    error.id = params.id;
    error.cause = cause?.message;
    error.url = url;
    error.uuid = params.uuid;
    throw error;
  }
};

/**
 * Fetches an array of multiple deep link articles from NCP
 *
 * Can be used on the client or server
 */
export const fetchMultiDeeplinks = async <ItemType>({
  abortController,
  credentials = FETCH_CREDENTIALS.SAME_ORIGIN,
  params,
  headers = {},
}: {
  abortController?: AbortController;
  credentials?: FetchCredential;
  params: NCPDeeplinkViewParams;
  headers?: {
    Cookie?: string;
  };
}): Promise<ItemType[]> => {
  let baseUrl = "/api/v1/gql/content_view";
  if (NCP_ORIGIN) {
    baseUrl = new URL(baseUrl, NCP_ORIGIN).toString();
  }

  const searchParams = encodeParams({ ...params });
  const url = `${baseUrl}?${searchParams.toString()}`;

  try {
    const response = await fetch(url, {
      credentials,
      headers,
      method: "GET",
      signal: abortController?.signal,
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    const responseBody: NCPDeeplinkViewResponse<ItemType> =
      await response.json();
    return responseBody.data.contents;
  } catch (cause: any) {
    const error: any = new Error("Error fetching multi deeplink");
    error.id = params.id;
    error.cause = cause?.message;
    error.url = url;
    error.uuid = params.uuid;
    throw error;
  }
};

export const astroFetch = async (astroRecPath: string) => {
  const url = `${SUPERNOVA_S3}${astroRecPath}`;
  const res = await fetchWithPerf(url, { credentials: "omit" });
  if (!res.ok) {
    throw new FetchError(res);
  }
  return await res.json();
};

export const createAstroFetch = (astroRecPath: string) => {
  return async <ItemType>(
    streamArgs: FetchStreamArgs<ItemType>,
  ): Promise<NCPStream<ItemType>> => {
    const hydrateAstroStream = async (astroIds: string[]) => {
      const urlParams = {
        ...streamArgs.params,
        id: "ynews-deeplink-foryou",
        namespace: "news",
        uuid: astroIds.join(","),
        version: "v1",
      };
      const ncpUrl = `${NCP_ORIGIN}/api/v1/gql/content_view?${encodeParams(urlParams).toString()}`;
      const response = await fetchWithPerf(ncpUrl);
      if (!response.ok) {
        throw new FetchError(response);
      }
      return response.json();
    };

    const ASTRO_ID_BATCH_SIZE = 7;
    const nextPaginationCursor = streamArgs.paginationCursor;
    let nextPageIds = [];

    try {
      if (!nextPaginationCursor) {
        const response = await astroFetch(astroRecPath);
        nextPageIds = response.data;
      } else {
        nextPageIds = JSON.parse(nextPaginationCursor || "[]");
      }
      const responseBody = await hydrateAstroStream(
        nextPageIds.slice(0, ASTRO_ID_BATCH_SIZE),
      );

      const remainingIds = nextPageIds.slice(ASTRO_ID_BATCH_SIZE);

      return {
        nextPage: remainingIds.length > 0,
        pagination: {
          uuids: JSON.stringify(remainingIds),
        },
        ...responseBody.data[streamArgs.streamKey],
        stream: responseBody.data.contents,
      };
    } catch (cause: any) {
      // throw to force YCPI to redirect to https://brb.yahoo.net
      const error: any = new Error("Error fetching astro stream.");
      error.streamId = streamArgs.params.id;
      error.cause = cause?.message;
      throw error;
    }
  };
};

export const fetchStream = async <ItemType>({
  abortController,
  credentials = FETCH_CREDENTIALS.INCLUDE,
  isItemType,
  paginationCursor,
  params,
  streamKey,
  headers = {},
}: FetchStreamArgs<ItemType>): Promise<NCPStream<ItemType>> => {
  let baseUrl = "/api/v1/gql/stream_view";
  if (NCP_ORIGIN) {
    baseUrl = new URL(baseUrl, NCP_ORIGIN).toString();
  }

  const body = paginationCursor
    ? JSON.stringify({
        gqlVariables: {
          [streamKey]: { pagination: { uuids: paginationCursor } },
        },
      })
    : undefined;

  const searchParams = encodeParams(params);
  const url = `${baseUrl}?${searchParams.toString()}`;

  const fetchParams = {
    body,
    credentials,
    headers,
    method: body ? "POST" : "GET",
    signal:
      abortController?.signal ||
      (typeof window === "undefined" ? AbortSignal.timeout(700) : null),
  };

  try {
    const response = await fetchWithPerf(url, fetchParams);

    if (!response.ok) {
      throw new FetchError(response);
    }

    const jsonResponse = await response.json();
    const streamData = jsonResponse.data[streamKey];
    const stream = streamData.stream.filter(isItemType);

    return {
      ...streamData,
      stream,
    };
  } catch (cause: any) {
    const error: any = new Error("Error fetching stream_view.");
    error.streamId = params.id;
    error.cause = cause?.message;
    error.url = url;
    throw error;
  }
};

export const fetchContent = async <ItemType>({
  abortController,
  credentials = FETCH_CREDENTIALS.INCLUDE,
  isItemType,
  paginationCursor,
  params,
  streamKey,
  headers = {},
}: FetchStreamArgs<ItemType>): Promise<NCPStream<ItemType>> => {
  let baseUrl = "/api/v1/gql/content_view";
  if (NCP_ORIGIN) {
    baseUrl = new URL(baseUrl, NCP_ORIGIN).toString();
  }

  const body = paginationCursor
    ? JSON.stringify({
        gqlVariables: {
          [streamKey]: { pagination: { uuids: paginationCursor } },
        },
      })
    : undefined;

  const searchParams = encodeParams(params);
  const url = `${baseUrl}?${searchParams.toString()}`;

  const fetchParams = {
    body,
    credentials,
    headers,
    method: body ? "POST" : "GET",
    signal:
      abortController?.signal ||
      (typeof window === "undefined" ? AbortSignal.timeout(700) : null),
  };

  try {
    const response = await fetchWithPerf(url, fetchParams);

    if (!response.ok) {
      throw new FetchError(response);
    }

    const jsonResponse = await response.json();
    const streamData = jsonResponse.data[streamKey];
    const stream = jsonResponse.data.contents.filter(isItemType);
    return {
      ...streamData,
      stream,
    };
  } catch (cause: any) {
    const error: any = new Error("Error fetching content_view.");
    error.streamId = params.id;
    error.cause = cause?.message;
    error.url = url;
    throw error;
  }
};

export const fetchLiveBlogData = async (
  params: NCPLiveBlogParams,
): Promise<NCPLiveBlog | null> => {
  let baseUrl = "/api/v2/gql/content_view";

  if (NCP_ORIGIN) {
    baseUrl = new URL(baseUrl, NCP_ORIGIN).toString();
  }

  const searchParams = encodeParams({ ...params });
  const url = `${baseUrl}?${searchParams.toString()}`;

  try {
    const response = await fetchWithPerf(url, {
      signal: AbortSignal.timeout(1000),
    });
    const liveBlogData = await response.json();
    return liveBlogData.data.contents[0].content;
  } catch (error) {
    console.error(error);
    return null;
  }
};
